import { useEffect, useState } from "react";
import { Input,message,Select,Drawer,InputNumber , Button, Popover,Tabs,Modal,Image} from 'antd';
import { Link, useNavigate ,useLocation } from 'react-router-dom'
import {httpGet,httpPost} from '../../utils/httpUtils';
import { State, useAppDispatch, useAppSelector } from "../../app/Store";
import {UserHeader} from "./UserHeader";
import {Share} from "./Share";
import { Timeline } from 'react-twitter-widgets'
import {PushDonate} from "./PushDonate";
import { Formatter } from './Formatter';
import { Common } from "../../app/Common";
import Web3 from 'web3';

interface CommentVo{
	projectId:any,
	content:any
}


interface ProjctInfo{
	erc20Address:any,
	feeRate:any,
	isErc20:any,
	nftMainId:any,
	projectId:any,
	receivingAddress:any,
	allowance:any

}


export function CProject() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { TextArea } = Input;
	let query = new URLSearchParams(useLocation().search);
	let  projectId =query.get("projectId");
	const [projct,setProjct] =  useState({}as any);
	const [commentList,setCommentList] =  useState([]as any);
	const [commentVo,setCommentVo] =  useState({}as CommentVo);
	useEffect(()=>{
		getDetail();
		getProjctCommentList();
		listByProjectId();
		getTwNews();
		donationProgress();
	},[]);


	function getDetail(){
		httpGet("/project/getDetail/"+projectId).then((e:any)=>{
			setProjct(e.data.data);
		})
	}

	function getProjctCommentList(){
		httpGet("/comment/getList/"+projectId).then((e:any)=>{
			if(e.data.code == 0 ){
				setCommentList(e.data.data);
			}
		})
	}


	function commentSubmitChild(id:any){
		httpPost("/comment/comment",{id:id,content:commentVo.content,superiorId:id}).then((e:any)=>{
			if(e.data.code == 0 ){
				message.success("comment is  success");	
				getProjctCommentList();
				getDetail();
			}else{
				localStorage.setItem("pageFrom","/#/Project?projectId="+projectId);
				navigate("/SignIn");
			}
		});
	}

	function commentSubmit(){
		httpPost("/comment/comment",{projectId:projectId,content:commentVo.content}).then((e:any)=>{
			if(e.data.code == 0 ){
				message.success("comment is  success");
				getProjctCommentList();
			}else{
				localStorage.setItem("pageFrom","/#/Project?projectId="+projectId);
				navigate("/SignIn");
			}
		});
	}


	function projectAttention(){
		httpGet("/attention/projectAttention/"+projectId).then((e:any)=>{
			if(e.data.code == 0 ){
				message.success("project attention is  success");
				getDetail();
			}else{
				localStorage.setItem("pageFrom","/#/Project?projectId="+projectId);
				navigate("/SignIn");
			}
		});
	}


	function commentAttention(item:any,index:any){
		httpGet("/attention/commentAttention/"+item.id).then((e:any)=>{
			if(e.data.code==0){
				commentList[index].isLike=1;
				setCommentList([...commentList]);
			}else{
				localStorage.setItem("pageFrom","/#/Project?projectId="+projectId);
				navigate("/SignIn");
			}
		})
	}


	function cancelAttention(){
		httpGet("/attention/cancelAttention/"+projectId+"/1").then((e:any)=>{
			if(e.data.code == 0 ){
				message.success("project cancel attention is  success");
				getDetail();
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Discover_list");
				navigate("/SignIn");
			}
		})
	}




	function projectCollect(){
		httpGet("/collect/projectCollect/"+projectId).then((e:any)=>{
			if(e.data.code==0){
				message.success("project cancel collect is  success");
				getDetail();
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Discover_list");
				navigate("/SignIn");
			}
		})
	}


	function cancelCollect(){
		httpGet("/collect/cancelCollect/"+projectId+"/1").then((e:any)=>{
			if(e.data.code==0){
				getDetail();
			}
		})
	}



	function cancelCommentAttention(item:any,index:any){
		httpGet("/attention/cancelAttention/"+item.id+"/5").then((e:any)=>{
			if(e.data.code==0){
				commentList[index].isLike=0;
				setCommentList([...commentList]);
			}
		})
	}


	function showSendContent(indx:any,item:any){
		for (let index = 0; index < commentList.length; index++) {
			if(indx !=index ){
				commentList[index].showSendContent=false;
			}
		}
		commentList[indx].showSendContent=!commentList[indx].showSendContent;
		setCommentList([...commentList]);
	}


	function windowOpen(url:any) {
		if(	url.indexOf("http")>-1){
			window.open(url, '_block');
		}else{
			window.open("http://"+url, '_block');
		}
	}

	function getMinStep(item:any){
		debugger
		let maxWidth=19.7917;
		let width =1+maxWidth/item.fundRaisingObjectives*(item.donateNum?item.donateNum:0);
		return <div className="project_step_blue" style={{width:width>maxWidth?`${maxWidth+"vw"}`:`${width+"vw"}`}} >
		</div>
	}


	function getMinStepItem(fundRaisingObjectives:any,donateNum:any){
		debugger
		let maxWidth=6.25;
		let width =0.5+maxWidth/fundRaisingObjectives*donateNum?donateNum:0;
		return <div className="donate_step_bule" style={{width:width>maxWidth?`${maxWidth+"vw"}`:`${width+"vw"}`}} >
		</div>
	}



	function getMinStepM(fundRaisingObjectives:any,donateNum:any){
		let maxWidth=38;
		let width =maxWidth/fundRaisingObjectives*donateNum;
		return <div className="mdonate_list_item_cld_num_step" style={{width:width>maxWidth?`${maxWidth+"vw"}`:`${width+"vw"}`}} >
		</div>
	}


	const [allDonateList,setAllDonateList] =  useState({}as any);

	const [myDonateList,setMyDonateList] =  useState({}as any);


	function listByProjectId(){
		httpGet("/donaterecord/listByProjectId/"+projectId).then((e:any)=>{
			if(e.data.code==0&& e.data.data){
				setAllDonateList(e.data.data)
			}
		})
	}


	const [twList,setTwList] =  useState({}as any);
	function getTwNews(){
		httpGet("/twitter/getTweetList/"+projectId).then((e:any)=>{
			if(e.data.code==0&& e.data.data){
				setTwList(e.data.data)
			}
		})
	}



	function listRelatedByProjectId(){
		httpGet("/donaterecord/listRelatedByProjectId/"+projectId).then((e:any)=>{
			if(e.data.code==0&& e.data.data){
				setMyDonateList(e.data.data)
			}
		})
	}


	function tabsClick(key:any){

		if(key == 1){
			getProjctCommentList();
		}
		if(key == 2){
			listRelatedByProjectId();
			listByProjectId();
		}

	}
	  function getTw(tw:any){
		if(tw && tw.indexOf("https") > -1){
			return projct.twitter.replace("https://twitter.com/","");
		}else{
			return "";
		}

	  }

	  const [plateNftList,setPlateNftList] =  useState([]as any);

	  function donationProgress(){
		httpGet("/project/donationProgress/"+projectId).then((e:any)=>{
			if(e.data.code==0&& e.data.data){
				setPlateNftList(e.data.data)
			}
		})
	  }

 let  Web=<div  className="project_body" >
		{projct.id?<div  className="project_left"  >
		<div className="project_title" >
		{projct.name}
		</div>
		<div  className="personer_detail" >
			<UserHeader member={projct.memberEntity}  ></UserHeader>
			<div>{projct.memberEntity ? projct.memberEntity.userName:""}</div>
			<div  className="personer_detail_time" >
			<Formatter dateTime={projct.createDateTime} ></Formatter>
			</div>
			{/* <img src={require("../../assets/v2/tw_bk.png")}  onClick={()=>{windowOpen(projct.twitter)}}   alt="" style={{marginLeft:"2.3438vw"}} className="project_share_img" /> */}
			<img src={require("../../assets/v2/net_bk.png")}  onClick={()=>{windowOpen(projct.officialWebsite)}}   alt="" className="project_share_img" />
		</div>

		<div className="project_text_desc" >
			{projct.description}
		</div>
		<div className="project_text_desc" >
			<div  
			 dangerouslySetInnerHTML={{__html:projct.detail}} 
			>
				{/* {projct.detail} */}
			</div>
		</div>
		<div className="project_text_desc" >
			{projct.imgUrl? JSON.parse(projct.imgUrl).map((item:any,index:any)=>{
				return  <Image src={item.url}  key={index}     style={{width:"14.9479vw",height:"9.3229vw",borderRadius:"1.0417vw",marginLeft:"10px"}} /> } ):""}
		</div>
		<div className="project_rate"   >
			The platform will draw <div  style={{color:"rgba(82, 165, 83, 1)",marginRight:"8px",marginLeft:"8px"}} >{projct.adminScale ? projct.adminScale: 0 }%</div> of the project
		</div>

		<div className="project_options"  >
	  			<div className="project_options_1" >
					<div className="project_token" >{projct.donateNum?projct.donateNum:0}{projct.receivingToken} / {projct.fundRaisingObjectives}{projct.receivingToken}</div>
					<div className="project_step" >
						{ getMinStep(projct)}
					</div>
				</div>
				<div className="project_options_2" >
				<div  className="options_box" >
					<div  className="options_box_flex options_box_flex_border"  >
						{projct.isAttentin==0?<img src={require("../../assets/v2/zan@2x.png")} onClick={()=>{projectAttention()}} className="dis_list_img"  alt="" />:<img src={require("../../assets/v2/zan_2.png")}   onClick={()=>{cancelAttention()}} className="dis_list_img"  alt="" />}
						<div className="options_box_num" >{projct.attentionNum}</div>
					</div>
					<div  className="options_box_flex options_box_flex_border"    >
						<img src={require("../../assets/v2/feed@2x.png")}  className="dis_list_img"   alt="" />
						<div className="options_box_num" >{projct.commentNum}</div>
					</div>

					<div  className="options_box_flex options_box_flex_border"  >
						{projct.isCollect==0? <img src={require("../../assets/v2/shoucang@2x.png")}  onClick={()=>{projectCollect()}}  className="dis_list_img"   alt="" />: <img src={require("../../assets/v2/shoucang_2.png")}  onClick={()=>{cancelCollect()}}  className="dis_list_img"   alt="" />}
					</div>
					<div  className="options_box_flex" >
						<Share   sharedata={projct}  type={'project'} ></Share>
					</div>
				</div>
					<PushDonate pages={"/Project?projectId="+projectId}  handleClick={getDetail} projct={projct} ></PushDonate>
				</div>
		</div>

		<div  className="project_options"  style={{flexWrap:"wrap",justifyContent:"flex-start"}} >
				{plateNftList.map((ite:any,inx:any)=>{
					return  <div key={inx} className="donate_list_item"    style={{marginRight:30,width:"11.3542vw",cursor:"pointer"}}  >
								<div className="donate_list_item_cld" >
								<Image src={JSON.parse(ite.image)[0].url} alt="" height={"9.5313vw"}  width={"11.3542vw"} className="donate_list_item_cld_img" />

								<div className="donate_list_item_cld_name" style={{fontSize:"0.625vw"}} >{ite.name}</div>
								
								<div  className="flex_width100 donate_list_item_cld_num " style={{fontSize:"0.625vw"}} >
									<div  className="donate_num"  >{ite.donationAmount}{ite.tokenName} / {ite.handselValue}{ite.tokenName}</div>
									<div className="donate_step" >{getMinStepItem(ite.handselValue,ite.donationAmount)}</div>
								</div>
								</div>
							</div>
				})}
		</div>
		<div className="project_comment_box" >
		<Tabs  onTabClick={tabsClick}  >
		<Tabs.TabPane tab="Feedback" key="1">
				<div className="project_comment_input"  >
					<TextArea placeholder="Your Mint Details"
					autoSize={{ minRows: 2, maxRows: 6 }}
					onChange={(e)=>{
						commentVo.content=e.target.value;
						setCommentVo({...commentVo});
					}}
					bordered={false} style={{width:"40.1042vw",height:"9.8438vw"}} />
					<div className="send_but_box" >
							<div className="send_but but-shadow"  onClick={commentSubmit} >
								SEND
							</div>
					</div>
				</div>
				<div  className="project_comment_msg" >
					{commentList.map((item:any,index:any)=>{
						return 	<div  key={index} style={{marginTop:30}} >
						<div className="comment_item" >
							<div className="comment_item_1"  >
								<UserHeader member={item.memberEntity}  ></UserHeader>
								<div className="comment_item_1_name" >{item.memberName}</div>
							</div>
							<div className="comment_item_2"  >{item.content}</div>
							<div className="comment_item_3"  >
								<Formatter dateTime={item.createDateTime} ></Formatter>
								</div>
						</div>
					</div>
					})}
				</div>
		</Tabs.TabPane>
		<Tabs.TabPane tab="Donation Information" key="2">
			<div  className="project_relevant" >
				{myDonateList.length > 0 ? myDonateList.map((item:any,index:any)=>{
					return <div  key={index} className="project_reading_item"  >
							<img src={item.headImg?
							item.headImg:require("../../assets/v2/logo.png")}
							className="project_reading_item_img"    alt="" />

							<div className="project_reading_item_2" >
								{item.memberName}
							</div>
							<div className="project_reading_item_3" >
								Donated {item.amountEth}{item.receivingToken}.
							</div>
							<div className="project_reading_item_4" >
							<Formatter dateTime={item.createDateTime} ></Formatter>
							</div>
							<div className="project_reading_item_5"   onClick={()=>{
							window.open("https://goerli.etherscan.io/tx/"+item.txnhash);
						}}>
								wallet:{item.senderAddress}
							</div>
					</div>
				}):<div></div>}
			</div>


			<div  className="project_relevant" >
				{allDonateList.length > 0 ? allDonateList.map((item:any,index:any)=>{
					return <div   key={index} className="project_reading_item"  >
								<UserHeader member={item.memberEntity} ></UserHeader>
							<div className="project_reading_item_2" >
								{item.memberName}
							</div>
							<div className="project_reading_item_3" >
								Donated {item.amountEth}{item.receivingToken}.
							</div>
							<div className="project_reading_item_4" >
							<Formatter dateTime={item.createDateTime} ></Formatter>
							</div>
							<div className="project_reading_item_5"  onClick={()=>{
							window.open("https://goerli.etherscan.io/tx/"+item.txnhash);
						}}  >
								transaction:{item.senderAddress}
							</div>
					</div>
				}):<div></div>}
			</div>
		</Tabs.TabPane>

		</Tabs>

		</div>
	</div>:<div  className="project_left" ></div>}

	<div className="project_right" >
		<div  className="projectflex project_right_list" >
			<div  className="projectflex" style={{width:"50%",marginLeft:"10px",alignItems:"center"}} >
				<div className="project_status project_status_active">
					News
				</div>
				<img src={require("../../assets/mobile/lesd@2x.png")} style={{width:'22px',height:'22px',marginLeft:"10px",marginTop:"20px"}}   alt="" />
			</div>
			<div  className="project_tw_list"  style={{marginTop:20,overflow:"auto",width:"24.7396vw"}} >
				{projct.twitter?	<Timeline
				dataSource={{
					sourceType: 'profile',
					screenName: getTw(projct.twitter)
				}}
				options={{
					height: '1200',
					width:'24.7396vw'
				}}
				/>:<div></div>}
			</div>
		</div>
	</div>
	</div>


 let  Mobile=projct.id?<div  className="mproject_box" >
			<div className="mproject_name" >
				Reading time that the modern people spend on  media.
			</div>
			<div  className="mpersoner_detail" >
			<UserHeader member={projct.memberEntity}  ></UserHeader>
			<div>{projct.memberEntity ? projct.memberEntity.userName:""}</div>
				<div  className="personer_detail_time" >
					<Formatter dateTime={projct.createDateTime} ></Formatter>
				</div>
			</div>

		<div className="mproject_text_desc" >
				{projct.description}
			</div>
			<div className="mproject_text_desc" >
				{projct.detail}
			</div>
			<div className="mproject_img" >
				{projct.imgUrl? JSON.parse(projct.imgUrl).map((item:any,index:any)=>{
					return  <Image src={item.url}  key={index}     style={{width:"42vw",height:"30vw",borderRadius:"1.0417vw",marginTop:5,marginLeft:"10px",objectFit:"cover"}} />  } ):""}
			</div>




			<div className="mproject_donate" >
					 <PushDonate pages={"/Project?projectId="+projectId}  handleClick={getDetail} projct={projct} ></PushDonate>
					<div  className="moptions_box" >
						<div  className="moptions_box_flex"  >
							{projct.isAttentin==0?<img src={require("../../assets/v2/zan@2x.png")} onClick={()=>{projectAttention()}} className="moptions_box_img"  alt="" />:<img src={require("../../assets/v2/zan_2.png")}   onClick={()=>{cancelAttention()}} className="moptions_box_img"  alt="" />}
							<div className="moptions_box_num" >{projct.attentionNum}</div>
						</div>
						<div  className="moptions_box_flex "    >
							<img src={require("../../assets/v2/feed@2x.png")}  className="moptions_box_img"   alt="" />
							<div className="moptions_box_num" >{projct.commentNum}</div>
						</div>
						<div  className="moptions_box_flex "  >
							{projct.isCollect==0? <img src={require("../../assets/v2/shoucang@2x.png")}  onClick={()=>{projectCollect()}}  className="moptions_box_img"   alt="" />: <img src={require("../../assets/v2/shoucang_2.png")}  onClick={()=>{cancelCollect()}}  className="moptions_box_img"   alt="" />}
						</div>
					</div>
			</div>

			<div  className="project_options"  style={{flexWrap:"wrap",justifyContent:"space-between"}} >
				{plateNftList.map((ite:any,inx:any)=>{
					return  <div key={inx} className="cdonate_list_item"    style={{width:"42.3542vw",cursor:"pointer"}}  >
								<div className="cdonate_list_item_cld" >
									<img src={JSON.parse(ite.image)[0].url} alt=""  className="mproject_nft_img" />
									<div className="cdonate_list_item_cld_name" style={{fontSize:"2.25vw"}} >{ite.name}</div>
									<div  className="flex_width100 cdonate_list_item_cld_num " style={{fontSize:"2.25vw"}} >
									<div  className="cdonate_num"  >{ite.donationAmount}{ite.tokenName} / {ite.handselValue}{ite.tokenName}</div>
									</div>

									<div className="mbadge_your_current_step"  >
										<div  className="mdonate_list_item_cld_num " style={{width:"38vw",paddingLeft:5}}  >
											<div className="cdonate_step" >{getMinStepM(ite.handselValue,ite.donationAmount)}</div>
										</div>
									</div>
									
								</div>
							</div>
				})}
			</div>

			<div  style={{width:"100%",justifyContent:"center",display:"flex"}} >
				<div className="mproject_comment_input"  >
					<TextArea placeholder="Your Mint Details"
					autoSize={{ minRows: 2, maxRows: 6 }}
					onChange={(e)=>{
						commentVo.content=e.target.value;
						setCommentVo({...commentVo});
					}}
					bordered={false}  className="mproject_comment_input_text" />
					<div className="msend_but_box" >
							<div className="msend_but"  onClick={commentSubmit} >
								SEND
							</div>
					</div>
					</div>
			</div>

			<div  className="mproject_comment_msg" >
			{commentList.map((item:any,index:any)=>{
				return 	<div  key={index} style={{marginTop:30}} >
				<div className="mcomment_item" >
					<div className="mcomment_item_1"  >
						<UserHeader member={item.memberEntity}  ></UserHeader>
						<div className="mcomment_item_1_name" >{item.memberName}</div>
					</div>
					<div className="mcomment_item_content"  >{item.content}</div>
					<div className="mcomment_item_3"  >
							<Formatter dateTime={item.createDateTime} ></Formatter>
							{item.isLike==0?<img src={require("../../assets/v2/zan@2x.png")} onClick={()=>{commentAttention(item,index)}} className="mlive_img"  alt="" />:<img src={require("../../assets/v2/zan_2.png")}   onClick={()=>{cancelCommentAttention(item,index)}} className="mlive_img"  alt="" />}
							<div className="tiding_comment_item_3" style={{marginLeft:"20px"}}  onClick={()=>showSendContent(index,item)} >Reply</div>
						</div>

						{item.showSendContent? <div className="mproject_comment_input"  >
									<TextArea placeholder="Your Mint Details" 
									autoSize={{ minRows: 2, maxRows: 6 }}
									onChange={(e)=>{
										commentVo.content=e.target.value;
										setCommentVo({...commentVo});
									}}
									bordered={false} className="mproject_comment_input_text" />

									<div className="msend_but_box" >
											<div className="msend_but"  onClick={()=>commentSubmitChild(item.id)} >
												SEND
											</div>
									</div>
								</div>:""}
				</div>
			</div>
			})}
		</div>

		 </div>:<div></div>


return Common.isMobile ? Mobile : Web
}
